import React, {useEffect} from 'react';
import './App.css';
import Footer from './components/Footer';
import Navigation from './components/navigation';
import Alert from './components/toast';
import RouteContainer from './routes';
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize('G-YGM9BRRYPW');
    useEffect(() => {
        (function(e: any,t,n){
            if(e.snaptr) return;
            var a: any = e.snaptr = function(){
                a.handleRequest ? a.handleRequest.apply(a,arguments) : a.queue.push(arguments)
            };
            a.queue = [];
            var s = 'script';
            var r: any = t.createElement(s);
            r.async = !0;
            r.src = n;
            var u:any = t.getElementsByTagName(s)[0];
            u.parentNode.insertBefore(r,u);
        })(window, document, 'https://sc-static.net/scevent.min.js');


        // @ts-ignore
        snaptr('init', 'be0d2e12-a2de-40e4-9bf0-f430359329b6', {});
        // @ts-ignore
        snaptr('track', 'PAGE_VIEW');
    }, []); // Run once when component mounts


  return (
    <React.Fragment>
      <Alert />
      <Navigation />
      <RouteContainer />
      <Footer />
     
    </React.Fragment>
  );
}

export default App;
